<template>  
  <div>
    <div class="card" v-if="indicators">
      <div class="card-header pl-3 pr-3 pt-1 pb-1">              
        <div class="row">
          <div :class="'col-md-' + indicators.cols" v-for="item in indicators.data" :key="item.reference">          
            <div class="widget-cbf-border-start widget-cbf-border-start-4 py-1 px-3" :style="'border-left-color:' + item.color + ' !important;'">
              <div class="font-weight-bold widget-cbf-text-secondary-default">{{item.title}}</div>
              <div class="font-weight-bold">{{item.value}}</div>
            </div>
          </div>        
        </div>
      </div>      
    </div>    
    
    <BarChart :chartData="chartData" :options="options" />        
  </div>
  
</template>

<script>
  import BarChart from './_chartBar'

  export default {
    components: {
      BarChart
    },
    props: {
      chartData: {
        type: Object,
        default: {}
      },
      options: {
        type: Object,
        default: {}
      }, 
      indicators: {
        type: Object,
        default: {},
      }     
    },        
  }
</script>
<style>
  .widget-cbf-border-start-4 {
    border-left-width: 4px !important;
  }  
  .widget-cbf-border-start {
    border-left: 1px solid #d8dbe0;
  }

  .widget-cbf-border-start-info {
    border-left-color: #20a8d8 !important;
  }  
  .widget-cbf-border-start-success {
    border-left-color: #4dbd74 !important;
  }

  .widget-cbf-text-secondary-default {
      --cui-text-opacity: 1;
      color: rgba(44, 56, 74, 0.681) !important;
  }  
</style>
